.cdk-global-overlay-wrapper, .cdk-overlay-container {
  z-index: 99999 !important;
}

.image-avatar {
  max-width: 90px;
  max-height: 90px;
  margin: -50px auto 0;
  border-radius: 50%;
  overflow: hidden;
  padding: 0;
  box-shadow: 0 16px 38px -12px rgba(0, 0, 0, 0.56), 0 4px 25px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2);
}

.image-avatar .img {
  width: 100%;
  height: auto;
}

.image-card {
  height: 100px;
  padding-top: 41px;
}

.table-responsive {
  display: inline;
  overflow: initial !important;
}

.card .card-body {
  overflow: auto;
  width: 100%;
}

.event_b_page .event_img img {
  width: 100%;
}

.pro_img .card-icon {
  width: 85px;
  height: 85px;
  background: #eee !important;
  border: 2px solid #ccc;
  color: #fff;
  overflow: hidden;
  padding: 0 !important;
  -webkit-box-shadow: 0px 5px 20px 1px rgba(0, 0, 0, 0.21) !important;
  -moz-box-shadow: 0px 5px 20px 1px rgba(0, 0, 0, 0.21) !important;
  box-shadow: 0px 5px 20px 1px rgba(0, 0, 0, 0.21) !important;
}

.pro_img .card-icon img {
  max-width: 100%;
}

.spekers_w .card .card-stats.pro_img {
  background: #f1eee4;
  box-shadow: none !important;
  padding: 0 7px 11px 0;
}

.delig_w .card .card-stats.pro_img {
  background: #f1eee4;
  box-shadow: none !important;
  padding: 0 7px 5px 0;
}

.spon_w .col-md-3 .card {
  box-shadow: none !important;
}

.spon_w .col-md-3 .card-body {
  background: #fff;
  box-shadow: none !important;
  border: 2px solid #f1eee4;
}

.delig_w .card.card-stats .card-footer {
  border-top: 1px solid #cfcbbd !important;
}

.sidebar .nav i {
  color: #B78637 !important;
}

.sidebar-background {
  background-image: none !important;
  background-color: #f1eee4 !important;
}

.sidebar .sidebar-wrapper {
  background: #f1eee4 !important;
}

.sidebar {
  box-shadow: 3px -5px 13px rgba(0, 0, 0, .1) !important;
}

.sidebar .logo::after {
  right: 0 !important;
  height: 1px !important;
  width: 100% !important;
}

.sidebar .nav li.active>a {
  color: #fff;
  background: #B78637;
}

.sidebar .nav li.active>a i {
  color: #fff !important;
  background: none !important;
}

.sidebar .nav li>a:hover, .sidebar .nav li>a:focus {
  background: #B78637 !important;
  color: #fff !important;
}

.sidebar .nav li>a:focus i {
  color: #fff !important;
}

.sidebar .nav li a:hover i {
  color: #fff !important;
}

.sidebar .nav li a:hover {
  background-color: #B78637 !important;
  color: #fff !important;
}

.sidebar .logo {
  height: auto;
  background: #fff;
  float: left;
  padding: 0 !important;
}

.sidebar .logo-img img {
  width: 100% !important;
  top: 0 !important;
  left: 0 !important;
  padding: 0 18px 9px !important;
  position: relative !important;
}

.sidebar .logo-img,
.sidebar .logo a.logo-mini {
  width: 100% !important;
  margin: 0 !important;
  max-height: 200px !important;
}

.card .card-header h4 {
  margin-bottom: 12px !important;
  float: left;
}

.card {
  border-radius: 15px !important;
}

.card-icon {
  background: #B78637 !important;
}

.btn-rose {
  opacity: 1 !important;
  background: #B78637 !important;
  border-radius: 10px !important;
  -webkit-box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.23) !important;
  box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.23) !important;
}

.card-header .btn {
  background: #b0a179 !important;
}

.text-primary th {
  color: #ba9322 !important;
}

.table-responsive td a {
  color: #ba9322 !important;
}

.navbar-minimize .visible-on-sidebar-regular {
  color: #B78637 !important;
}

.navbar .collapse .navbar-nav .nav-item .nav-link {
  color: #B78637 !important;
  padding: 10px 5px !important;
}

.navbar .navbar-nav {
  margin-right: 10px;
}

.mat-checkbox-checked.mat-accent .mat-checkbox-background, .mat-checkbox-indeterminate.mat-accent .mat-checkbox-background {
  background-color: #000 !important;
}

.mat-form-field.mat-focused .mat-form-field-ripple {
  background-color: #B78637 !important;
}

.mat-form-field.mat-focused .mat-form-field-label {
  color: #B78637 !important;
}

.event_b_page .card-header {
  background: none !important;
}

.event_list_w .card .card-header.card-header-image {
  margin: 10px;
  height: 236px;
  overflow: hidden;
  display: flex;
  align-items: center;
}

.event_list_w .card .card-header.card-header-image img {
    box-shadow: none;
    width: auto;
    max-width: 100%;
    height: auto;
    max-height: 100%;
    margin: 0 auto;
    align-items: center;
}

.event_list_w .card-body.text-left h4 {
  text-align: left !important;
  color: #B78637;
  margin-bottom: 18px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.event_list_w .card-body.text-left h4 a:hover {
  color: #B78637;
}

.event_list_w .card .card-body {
  padding-top: 3px;
  padding-bottom: 5px;
}

.event_list_w .card-category .notification {
  display: inline-block;
  position: absolute;
  margin-top: -10px;
  font-size: 14px;
  background-color: #B78637;
  padding: 3px;
  border-radius: 50%;
  width: 25px;
  box-shadow: 0 1px 8px -1px rgba(0, 0, 0, 0.56);
  text-align: center;
  color: #fff;
  margin-left: 8px;
  line-height: 20px;
  height: 25px;
}

.card_list_w h6.card-category {
  color: #333;
  margin-bottom: 18px;
  font-size: 12px;
}

.card_list_w h6.card-category small {
  font-size: 12px;
  color: #B78637 !important;
}

.card_list_w h6.card-category i {
  color: #6c6b6b;
  font-size: 21px;
}

.btn-yellow {
  background: #fff !important;
  border: 1px solid #B78637 !important;
  color: #B78637 !important;
}

.event_list_w .card-footer .btn {
  padding: 6px 12px !important;
}

.event_list_w .card-footer .btn.btn-yellow {
  border: none !important;
  background: #f1eee4 !important;
  box-shadow: none !important;
}

.event_list_w .col-xl-3 {
  min-width: 300px;
}

.main-panel .main-content {
  margin-top: 30px !important;
}

.event_list_detail_w .card-product {
  margin-top: 10px;
}

.search_bar_w .left {
  width: 80%;
  float: left;
}

.search_bar_w .right {
  width: 20%;
  float: left;
}

.search_bar_w .form-control {
  background: #fff !important;
  padding: 16px 15px;
  height: auto;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.14);
  border-radius: 7px 0px 0px 7px !important;
}

.search_bar_w .btn {
  background-color: #B78637 !important;
  color: #fff !important;
  padding: 15px;
  margin-top: 8px;
  border: none !important;
  border-radius: 0 7px 7px 0 !important;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.14);
}

.event_list_w .card .card-category .material-icons {
  top: 4px !important;
}

.add_btn_top {
  padding: 0 !important;
  float: right;
}

.add_btn_top .btn {
  margin-top: 8px;
  border-radius: 7px !important;
  padding: 14px 20px;
  background-color: #B78637 !important;
}

.l_r_wrap {
  float: right;
  min-width: 335px;
}

.event_list_w .card-product {
  margin-top: 12px;
}

.modal_n .close {
  color: #fff !important;
  opacity: 1 !important;
}

.modal_n .mat-form-field-label {
  font-size: 14px !important;
}

.modal_n .modal-content {
  border-radius: 15px !important;
  overflow: hidden;
}

.modal_n .modal-dialog .modal-header {
  background: #B68A35;
  color: #fff;
  border-radius: 15px 15px 0 0 !important;
  padding-top: 14px;
  padding-bottom: 11px;
}

.modal_n .modal-dialog .modal-header h5 {
  font-size: 18px !important;
}

.modal_n .modal-body form {
  margin-bottom: 0 !important;
}

.modal_n .fileinput .thumbnail {
  max-width: 150px !important;
  box-shadow: none !important;
}

.modal-backdrop {
  background: rgba(0, 0, 0) !important;
  z-index: 1040 !important;
}

.modal-backdrop.show {
  opacity: 0.5 !important;
}

.fileinput .btn {
  color: #B68A35 !important;
  border: 1px solid !important;
  background: none !important;
}

.modal_n .modal .modal-dialog {
  margin-top: 60px !important;
}

.card_list_w h6.card-category .to_text {
  font-size: 12px !important;
  color: #333 !important;
  padding: 0 9px;
}

.event_list_w .align_w {
  position: absolute !important;
  right: 12px !important;
  bottom: 0 !important;
  padding: 3px 4px !important;
  background: white !important;
  line-height: 15px !important;
  color: #9f9898 !important;
  box-shadow: none !important;
  text-transform: capitalize !important;
}

.event_list_w .align_w i {
  font-size: 17px;
  margin-top: -23px;
}

.event_list_detail_w .img_wrap img {
  max-width: 100%;
  width: 100% !important;
}

.venue_list .card-product {
  box-shadow: none !important;
}

.venue_list .card .map {
  margin-top: 0 !important;
}

.venue_list h6 .capitalize {
  text-transform: capitalize !important;
  font-weight: normal;
  color: #B78637 !important;
}

.card_list th {
  font-weight: bold !important;
  font-size: 13px !important;
  line-height: 7px;
}

.card_list {
  background: #f1eee4;
}

.eve_location {
  text-align: center;
}

.eve_location h3 {
  font-size: 19px;
  color: #B78637;
  font-weight: 500;
  margin-bottom: 1px;
}

.venue_list .card-header-image,
.venue_list .card .map {
  border-radius: 0 !important;
}

.venue_list .card-body {
  padding: 10px !important;
}

.card_list .align_w {
  position: relative !important;
  float: right !important;
  right: 0 !important;
}

.card_list table {
  margin-bottom: 0 !important;
}

.card_list td {
  padding: 6px 9px !important;
  font-size: 13px !important;
  line-height: 16px !important;
}

.date_style {
  background: #ffffff;
  border: 1px solid #d1b887;
  border-radius: 7px;
  width: auto;
  float: left;
  overflow: hidden;
  ;
}

.date_style .date {
  display: block;
  color: #fff;
  float: left;
  clear: left;
  background: #B68A35;
  width: 100%;
  text-align: center;
  font-size: 11px;
  line-height: 18px;
  font-weight: bold;
}

.date_style .time {
  display: block;
  color: #666666;
  float: left;
  clear: left;
  width: 100%;
  text-align: center;
  font-size: 10px;
  font-weight: bold;
  line-height: 9px;
  padding: 6px 2px;
}

.date_style .time sub {
  font-weight: normal;
  bottom: 0 !important;
  font-size: 9px;
}

.sidebar .nav li>a {
  margin: 0 !important;
}

.sidebar .nav li a, .sidebar .nav li .dropdown-menu a {
  border-radius: 0 !important;
}

#pages nav {
  margin-top: 0 !important;
}

.view_edit {
  padding: 0 !important;
}

.view_edit h5 {
  margin-bottom: 5px !important;
}

.view_edit .btn {
  border: 1px solid #B78637 !important;
  color: #B78637 !important;
  border-radius: 5px !important;
  margin: 0 !important;
  right: 8px !important;
}

.edit_btn_1 {
  width: 30px;
}

.view_btn_1 {
  width: 53px;
}

.view_btn_1 a {
  display: block;
  background: #B68A35;
  color: #fff !important;
  text-transform: uppercase;
  font-size: 10px;
  padding: 3px 7px !important;
  border-radius: 12px;
}

.table-responsive td.view_btn_1 a {
  color: #fff !important;
}

.text_left h4 {
  float: left;
  color: #B78637;
}

.add_btn .btn {
  background: #B78637 !important;
  margin-top: 0px !important;
  padding: 8px !important;
  border-radius: 50px !important;
  line-height: 10px !important;
  box-shadow: none !important;
}

.add_btn .btn i {
  margin: 0 !important;
}

.sponsor_list .card {
  margin: 0 !important;
  border: 2px solid #eae3d1;
  border-radius: 0 !important;
  box-shadow: none !important;
}

.sponsor_list .pro_img {
  padding: 5px;
  box-shadow: none !important;
  max-height: 45px;
  overflow: hidden;
}

.sponsor_list .pro_img img {
  width: 100%;
}

.sponsor_list .card-body {
  border: none !important;
  padding: 5px 10px !important;
}

.sponsor_list .card-body p {
  margin: 2px !important;
  color: #333333 !important;
}

.banner_up .btn {
  font-size: 10px !important;
  padding: 5px 8px !important;
  box-shadow: none !important;
  margin-top: 0px !important;
}

.spekers_w .speaker_img {
  width: 95px;
  height: 95px;
  border-radius: 50%;
  border: 2px solid #eae3d1;
  margin: -35px auto 0 auto;
  background: #fff;
  overflow: hidden;
}

.spekers_w .speaker_img img {
  max-width: 100%;
}

.speaker_list_w .card {
  border: 2px solid #eae3d1;
  border-radius: 0 !important;
  box-shadow: none !important;
  background: #f1eee4;
  margin-bottom: 0px;
}

.speaker_list_w .card-body {
  text-align: center;
  padding-top: 5px !important;
}

.speaker_list_w .card-body p {
  margin-bottom: 0;
  margin-top: 0;
}

.speaker_list_w .icons_align {
  width: 25px;
  position: absolute;
  right: 0;
  bottom: 0;
  color: #B78637;
}

.speaker_list_w .icons_align .btn {
  padding: 0 !important;
}

.speaker_list_w .icons_align .btn i {
  color: #B78637;
}

.delig_w .stats.text-success {
  width: 100%;
}

.delig_w .stats .td-actions {
  margin-left: auto;
}

.delig_w .stats .td-actions .btn {
  padding: 0 !important;
  color: #B78637 !important;
  background: none !important;
  box-shadow: none !important;
  font-size: 11px;
}

.delig_w .stats .td-actions .btn i {
  top: 0 !important;
}

.delg_w_l .card {
  background: #f1eee4;
  padding: 12px;
}

.delg_w_l .card .img_wrap_d {
  width: 30%;
  height: 100px;
  overflow: hidden;
  float: left;
}

.delg_w_l .card .img_wrap_d img {
  width: 100%;
  border-radius: 10px;
}

.delg_w_l .card-body {
  width: 70%;
  float: left;
}

.delg_w_l .card-body p {
  margin-bottom: 0 !important;
  margin-top: 0 !important;
}

.delg_w_l .card {
  display: inline-block;
  margin: 0;
  box-shadow: none;
}

.delg_w_l .card-footer {
  clear: left;
  border-top: 1px solid #e1d9d9 !important;
  margin: 0 !important;
}

body.login-page {
  background-color: #f1eee4 !important;
  background-image: url("./assets/img/logo_n.jpg") !important;
  background-size: cover;
}

/*
.login-page {
  background-image: none !important;
  background-color: #ececee !important;
}*/
body.login-page .header-filter::before {
  background: none !important;
}

.login_logo_w .card-header {
  background: none !important;
  border: none;
}

.login-img {
  width: auto;
  height: 120px;
}

h1.login_title {
    color: #b78637 !important;
    font-size: 22px !important;
    font-weight: 400 !important;
    line-height: 24px !important;
    margin-bottom: 0px !important;
    text-align: center;
    text-transform: uppercase;
    letter-spacing: 3px;
}

.login-page .card-login {
  padding-bottom: 20px;
  background: #fff;
  margin-top: 0 !important;
  padding: 22px 30px 20px !important;
  border: 1px #e1e1e1 solid;
}

.login-page .footer .copyright, .login-page .footer a {
  color: #807E7E !important;
  font-size: 12px;
}

.login-page .card-login .btn {
  width: 100% !important;
  padding: 14px !important;
  border-radius: 5px !important;
  box-shadow: none !important;
}

.login-page .card-body {
  padding: 0 !important;
}

.login-page .card-footer {
  margin: 0 !important;
}

.deligate_d_w .card-profile .card-avatar {
  margin-top: 0 !important;
  box-shadow: none !important;
  padding: 5px;
  border: 2px solid #eae3d1;
}

.deligate_d_w .card-profile .card-avatar img {
  border-radius: 50%;
}

.deligate_d_w .card-profile {
  padding-top: 15px;
  padding-bottom: 15px;
  margin-top: 0 !important;
  margin-bottom: 0;
  border: 1px solid #eae3d1;
  box-shadow: none !important;
  border-radius: 0 !important;
  padding-bottom: 10px;
}

.deligate_d_w .card-profile h3 {
  color: #3C4858;
  margin-bottom: 10px;
  font-weight: 500;
  margin-top: 0;
  font-size: 22px;
}

.deligate_d_w .card-body {
  margin-top: 10px !important;
  padding-top: 5px !important;
}

.deligate_d_w .card-profile h4 {
  color: #B78637;
}

.tab_wrap .nav-link {
  font-size: 12px !important;
  border-radius: 0 !important;
  padding: 5px 10px !important;
  box-shadow: none !important;
  text-transform: capitalize !important;
}

.tab_wrap .nav-link.active {
  background: #B78637 !important;
  border-radius: 0 !important;
  padding: 5px 10px !important;
  box-shadow: none !important;
  border-radius: 7px 7px 0 0 !important;
}

.tab_wrap .card {
  background: #f1eee4;
  box-shadow: none;
  margin-top: 0px;
  margin-bottom: 0;
  border-radius: 0 !important;
}

.tab_wrap .col-md-12 {
  padding: 0 10px 0;
}

.tab_wrap .upload_doc_list {
  background: #fff;
  float: left;
  width: 100%;
  padding: 15px;
  border-radius: 10px;
  margin-top: 7px;
}

.tab_wrap .upload_doc_btns .btn {
  color: #B78637 !important;
  background: none !important;
  border: 1px solid #B78637;
  text-transform: capitalize !important;
  box-shadow: none !important;
  border: 1px solid #eee !important;
  padding: 5px 10px !important;
}

.tab_wrap .upload_doc_btns .btn i {
  margin: 0 !important;
  margin-right: 8px !important;
}

.mat-button.btn.btn-info, .mat-raised-button.btn.btn-info, .mat-raised-button.btn:not([class*="mat-elevation-z"]).btn-info, .btn.btn-info {
  box-shadow: none !important;
}

.tab_wrap .upload_docs h6 {
  font-weight: normal;
  text-transform: capitalize !important;
  padding-bottom: 0px !important;
  margin-bottom: 4px;
}

.tab_wrap .doc_list h6 {
  border-bottom: 2px solid #f1eee4;
  padding-bottom: 5px;
}

.tab_wrap .upload_docs ul {
  margin: 0;
  padding: 0;
  border-top: 2px solid #f1eee4;
  padding-top: 5px;
  float: left;
  width: 100%;
}

.tab_wrap .upload_docs ul li {
  width: 36px;
  height: 50px;
  border: 1px solid #eee;
  float: left;
  margin-right: 18px;
  list-style: none;
  text-align: center;
  padding: 4px;
  overflow: hidden;
}

.tab_wrap .upload_docs ul li img {
  width: 100% !important;
}

.tab_wrap .doc_table_l tr {
  border-bottom: 1px solid #eee !important;
}

.tab_wrap .lists,
.tab_wrap .doc_list {
  float: left;
  width: 100%;
  margin-bottom: 10px;
}

.tab_wrap .doc_list td {
  padding: 0;
  color: #B78637;
  font-size: 12px;
}

.tab_wrap .nav {
  border-bottom: 2px solid #B78637;
  border-radius: 0 !important;
}

.tab_wrap .doc_l h6 {
  margin-bottom: 0 !important;
  font-size: 12px;
}

.assign_s_w .seat_icon i {
  font-size: 45px;
}

.assign_s_w {
  margin-top: 10px;
}

.assign_s_w .seat_text {
  max-width: 95px;
  margin: 0 auto;
}

.assign_s_w .seat_text input {
  border: 1px solid #c8c3b3;
  background: #fff;
  padding: 9px 9PX;
  border-radius: 8px;
  max-width: 100%;
  line-height: 18px;
  text-align: center;
}

.assign_s_w .seat_btn .btn {
  padding: 7px 15px !important;
  font-size: 10px;
  margin-top: 15px;
}

.assign_s_w .seat_icon {
  width: 66px;
  height: 66px;
  background: #fff;
  margin: 0 auto 10px;
  padding: 10px;
  border-radius: 72px;
}

.deligate_d_w .card .card-category {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.comp_list_w .card-profile .card-avatar {
  float: left;
  margin: 0 !important;
  border-radius: 0 !important;
  width: 15%;
  padding: 3px;
  border: 1px solid #eae3d1;
  max-height: 52px;
}

.comp_list_w .card-profile .card-avatar img {
  border-radius: 0 !important;
}

.comp_list_w .pro_det_w .card-body {
  width: 79%;
  text-align: left;
  padding: 5px;
  margin-top: 0 !important;
  padding-top: 0 !important;
  float: left;
}

.comp_list_w .pro_det_w .card-body p,
.comp_list_w .pro_det_w .card-body h6,
.comp_list_w .pro_det_w .card-body h4 {
  text-align: left;
}

.comp_list_w .pro_det_w .card-body p {
  font-size: 12px;
  margin-bottom: 0;
}

.comp_list_w .pro_det_w {
  width: 100%;
  padding: 10px;
  padding-bottom: 0px !important;
}

.comp_list_w .card-profile .tab_wrap .col-md-12 {
  padding: 10px;
}

.comp_list_w .card-profile {
  padding-bottom: 0 !important;
  padding-top: 10px !important;
}

.comp_list_w .card-profile h4 {
  margin-top: 0 !important;
  font-size: 14px;
}

.comp_list_w .card-profile h3 {
  text-align: left;
  font-size: 17px;
  padding: 0 10px 10px;
  border-bottom: 1px solid #eee;
  margin-bottom: 2px;
  color: #B78637;
}

.comp_list_w .card-profile h6 {
  font-size: 11px;
  line-height: 12px;
}

.comp_list_w .tab_wrap .card-body {
  padding: 5px;
}

.comp_list_w .tab_wrap .nav {
  padding: 0 !important;
}

.comp_list_w {
  padding-left: 0 !important;
  margin-bottom: 10px;
}

.tab_wrap thead {
  border-bottom: 2px solid rgba(0, 0, 0, 0.06);
}

.tab_wrap thead th {
  padding: 7px 4px !important;
}

.tab_wrap thead th h6 {
  font-size: 11px;
  line-height: 11px;
  text-transform: capitalize;
}

.tab_wrap td {
  padding: 7px 4px !important;
}

.tab_wrap .tab-pane .table tbody>tr>td:first-child {
  width: auto;
}

.tab_wrap .tab-pane .table tbody tr td {
  width: auto;
  padding: 3px 3px !important;
  font-size: 13px;
}

/*Modal forms style start*/
input.mat-input-element {
  background: #fff;
  border: 1px solid #ced4da;
  padding: 9px 8px;
  font-size: 13px !important;
  font-weight: normal !important;
}

textarea.mat-input-element {
  background: #fff !important;
  border: 1px solid #ced4da;
  padding: 9px 8px !important;
  font-size: 13px !important;
  font-weight: normal !important;
}

.modal-body {
  background: #f1eee4;
  border: 1px solid #cfcbbd;
}

.mat-form-field-appearance-legacy .mat-form-field-underline {
  display: none;
}

.mat-form-field-appearance-legacy .mat-form-field-label {
  top: 0 !important;
  font-size: 13px !important;
  font-weight: normal !important;
  color: #000;
}

.mat-form-field-appearance-legacy .mat-form-field-wrapper {
  padding-bottom: 3px !important;
  margin-bottom: 30px !important;
}

.mat-form-field.mat-focused .mat-form-field-label {
  top: 0 !important;
  transform: perspective(100px) !important;
  width: 100% !important;
}

.mat-form-field-label-wrapper {
  overflow: visible !important;
}

.mat-form-field-label {
  transform: none !important;
}

.mat-raised-button {
  padding: 9px 15px !important;
  border-radius: 50px !important;
  box-shadow: none !important;
}

.modal-dialog .modal-body {
  padding-bottom: 10px !important;
}

.mat-select {
  background: #fff;
  border: 1px solid #ced4da;
  padding: 10px;
}

.mat-select-panel {
  margin-left: 6px;
  max-width: 200px !important;
}

.mat-option {
  font-size: 14px !important;
  padding: 0 !important;
  margin: 0 !important;
  border-radius: 0 !important;
  padding: 0 18px !important;
}

.mat-option:hover {
  background: #f1eee4 !important;
}

.mat-option.mat-active {
  background: #fff !important;
  color: #B68A35 !important;
}

.mat-option.mat-selected:hover {
  padding: 20px !important;
}

.mat-button-focus-overlay {
  background: none !important;
}

.card.card-login .card-body {
  overflow: visible;
}

.modal-title {
  font-weight: normal !important;
}

/*Modal forms style end*/
.add_btn_n .btn {
  margin-top: 23px !important;
}

.mat-datepicker-toggle .mat-icon-button {
  font-size: 50px !important;
  padding: 0 !important;
  height: auto !important;
  width: auto !important;
}

.contact_list_w .table-responsive {
  background: #f1eee4;
}

.contact_list_w .table-responsive table {
  margin-bottom: 0 !important;
}

.contact_list_w table th {
  background: #e3decc;
  font-size: 13px !important;
  padding: 9px !important;
  line-height: 13px;
}

.contact_list_w table td {
  padding: 9px !important;
  line-height: 19px;
  font-size: 13px;
}

.contact_list_w .table {
  background: #f1eee4;
}

.breadcrumb {
  background: none !important;
  margin-bottom: 0 !important;
}

.breadcrumb li {
  color: #B78637;
  font-size: 15px;
}

.breadcrumb li a {
  color: #B78637;
}

.card .card-body {
  overflow: inherit;
}

/* chat */
.chat-window {
  bottom: 0;
  position: fixed;
  float: right;
  margin-left: 10px;
}

.chat-window>div>.panel {
  border-radius: 15px;
  background: #fff;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.14);
  overflow: hidden;
}

.chat-window .icon_minim {
  padding: 2px 10px;
}

.chat-window .msg_container_base {
  background: #fff;
  margin: 0;
  padding: 0 10px 10px;
  max-height: 300px;
  overflow-x: hidden;
}

.chat-window .top-bar {
  background: #B78637;
  color: white;
  position: relative;
  overflow: hidden;
}

.chat-window .msg_receive {
  padding-left: 0;
  margin-left: 0;
}

.chat-window .msg_sent {
  padding-bottom: 20px !important;
  margin-right: 0;
}

.chat-window .messages {
  background: #f1eee4;
  padding: 10px;
  border-radius: 2px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
  max-width: 100%;
}

.chat-window .messages>p {
  font-size: 13px;
  margin: 0 0 0.2rem 0;
}

.chat-window .messages>time {
  font-size: 11px;
  color: #ccc;
}

.chat-window .msg_container {
  padding: 10px;
  overflow: hidden;
  display: flex;
}

.chat-window img {
  display: block;
  width: 100%;
}

.chat-window .avatar {
  position: relative;
}

.chat-window .base_receive>.avatar:after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  width: 0;
  height: 0;
  border: 5px solid #FFF;
  border-left-color: rgba(0, 0, 0, 0);
  border-bottom-color: rgba(0, 0, 0, 0);
}

.chat-window .base_sent {
  justify-content: flex-end;
  align-items: flex-end;
}

.chat-window .base_sent>.avatar:after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0;
  height: 0;
  border: 5px solid white;
  border-right-color: transparent;
  border-top-color: transparent;
  box-shadow: 1px 1px 2px rgba(black, 0.2);
}

.chat-window .msg_sent>time {
  float: right;
}

.chat-window .msg_container_base::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #F5F5F5;
}

.chat-window .msg_container_base::-webkit-scrollbar {
  width: 12px;
  background-color: #F5F5F5;
}

.chat-window .msg_container_base::-webkit-scrollbar-thumb {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
  background-color: #555;
}

.chat-window h3 {
  color: #fff;
  font-size: 18px;
  margin: 10px;
}

.chat-window .panel-heading.top-bar .col-md-4.col-xs-4 {
  display: none;
}

.chat-window .panel-footer {
  background: #fff;
  padding: 10px 10px 10px 25px;
  border-top: 1px solid #eae4d0;
  box-shadow: 3px 16px 13px 7px;
}

/* chat end */
.card [class*="card-header-"] .card-icon, .card [class*="card-header-"] .card-text {
  padding: 0px !important;
  margin-top: 11px !important;
}

.card-header .card-icon {
  box-shadow: none !important;
  border-radius: 8px !important;
}

.card-header .btn {
  background: #B78637 !important;
}

.bg_table_n {
  background: #f1eee4 !important;
}

.bg_form_n {
  background: #f1eee4 !important;
  padding: 20px !important;
}

.delig_w .table-responsive table {
  background: #f1eee4 !important;
}

.event_list_detail_w .row.border-bottom .col-md-8 p {
  clear: right;
}

.card-product {
  margin-top: 10px !important;
}

.contact_list_w .card {
  margin-top: 10px !important;
}

.search_n_w input {
  background: #fff !important;
  border: 1px solid #e1d9d9 !important;
  padding: 13px 10px;
  border-radius: 8px;
  height: 42px;
}

.search_n_w .form-group {
  width: 70%;
  float: left;
  margin-top: 0;
}

.search_n_w .add_btn_n {
  width: 30%;
  float: left;
}

.search_n_w .add_btn_n .btn {
  margin-top: 2px !important;
  float: right;
}

.list_modal_n .table td {
  padding: 12px 6px !important;
}

.list_modal_n .form-check {
  margin-bottom: 0 !important;
}

.list_scroll {
  max-height: 500px;
  overflow-y: scroll;
  float: left;
  width: 100%;
}

.form-check .form-check-sign::before {
  background: none !important;
}

.admin_p_t {
  text-align: center;
  text-transform: uppercase;
  margin-top: 0 !important;
  font-size: 18px !important;
  font-weight: 500 !important;
  color: #b78637 !important;
  text-align: center !important;
}

.title_event_w {
  background: none !important;
  box-shadow: none !important;
}

.title_event_w .event_content {
  clear: right;
}

.title_event_w .event_content .card-footer {
  display: block;
  max-width: 300px;
  margin: 0 auto;
  text-align: center;
}

.title_event_w .event_content .card-title {
  text-align: center !important;
  font-weight: bold;
  color: #B78637 !important;
}

.title_event_w .card-body {
  padding-bottom: 0 !important;
  padding-top: 0 !important;
}

.event_content .stats {
  margin: 0 5px !important;
}

p {
  white-space: pre-wrap;
}

/*Google Map Start*/
.googleMap {
  height: 350px;
}

/*Google Map End*/
.modal_n .mat-form-field-subscript-wrapper {
  position: relative;
  margin-top: -4px !important;
  margin-bottom: 10px !important;
}

.modal_n .mat-form-field-subscript-wrapper .mat-error {
  font-size: 11px !important;
}
.btn_lt{
  background: #B78637 !important;
  margin-top: 12px !important;
}
.mat-form-field-subscript-wrapper{
  margin-top: 11px !important;
}
.fileinput .thumbnail{
  border: 1px solid #eae5e5;
  box-shadow: none !important;
}
.sidebar .nav li.active > [data-toggle="collapse"] i{
  color: #848588 !important;
}
.sidebar .nav li.active > a:focus i{
  color: #fff !important;
}
.sidebar .nav li.active > a:hover i{
  color: #fff !important;
}
.delete-badge{
  float: right;
  margin: -7px -8px 0px 0px;
  /* border: 1px solid #f44336; */
  color: #fff !important;
  border-radius: 50% !important;
  padding: 3px 5px !important;
  background: #B78637;
}

.delete-document-badge{
  float: right;
    margin: -11px -8px 0px -5px;
    /* border: 1px solid #f44336; */
    color: #fff !important;
    border-radius: 50% !important;
    padding: 3px 5px !important;
    background: #B78637;
    position: absolute;
}

.badge-danger{
  background: #B78637 !important;
}

.delagate-basic-details{
text-align: center;
}
.delagate-basic-details .delegate-profile-picture{
  height: 190px;
  width: 190px;
  padding:5px;
  border:1px solid #9f9898;
  border-radius: 50%;
  display: block;
}

.delagate-basic-details .delegate-name{
  font-size: 25px;
  font-weight: 800;
  display: block;
  color:#B78637;
  margin: 10px auto;
  }

  .delagate-basic-details p{
    margin: 3px auto;
  }
  .event_b_page  .table-responsive,
  .deligate_detail_w .contact_list_w .table-responsive{
    background: #f1eee4;
    height: 300px;
    overflow: scroll !important;
  }
.small-profile-picture{
  padding: 0.25rem;
  background-color: #fafafa;
  border: 1px solid #dee2e6;
  border-radius: 5px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.075);
  width: 33px;
  height: 33px;
}
.chat-profile-picture{
  padding: 0.25rem;
  background-color: #fafafa;
  border: 1px solid #dee2e6;
  border-radius: 5px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.075);
  width: 47px !important;
  height: 47px !important;
}
.profile-picture{
  padding: 0.25rem;
  background-color: #fafafa;
  border: 1px solid #dee2e6;
  border-radius: 5px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.075);
  width: 100px !important;
  height: 100px !important;
}

.hotel-pictures{
  max-height: 250px;
  overflow:scroll;
  width: 100%;
  padding: 10px;
}
.clock-face{
  direction : ltr !important;
}
.clock-face__container{
  direction : ltr !important;
}
.table-responsive.bg_table_n {
  overflow-y: scroll !important;
}

.mat-option.ng-star-inserted.mat-selected.mat-active{
  box-shadow: none !important;
  background: #B78637 !important;
}

@media only screen and (max-width: 1780px) {
  .comp_list_w .nav-pills .nav-item .nav-link {
    font-size: 10px !important;
    padding: 5px !important;
    min-width: 50px;
    line-height: 11px;
  }

  .comp_list_w .nav .nav-item {
    margin-left: 0 !important;
  }

  .event_list_w .card .card-header.card-header-image {
    max-height: 173px;
  }
}
.drop_filter {
  background: #fff !important;
    padding: 10px 4px 10px 4px;
    height: auto;
    box-shadow: 0 1px 4px 0 rgb(0 0 0 / 14%);
    border-radius: 7px 0px 0px 7px !important;
}

 
.ngx-pagination .current {
  padding: 0.1875rem 0.625rem;
  background: #b78636 !important;
  color: #fefefe;
  cursor: default;
  border-radius: 50px !important;
}
.ngx-pagination a {
  border-radius: 50px !important;
}