.rtl {
  font-family: 'Tajawal', sans-serif !important;
  .h1,
  .h2,
  .h3,
  .h4,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: 'Tajawal', sans-serif !important;
  }


  .mat-checkbox {
    font-family: 'Tajawal', sans-serif !important;
  }

  .mat-checkbox-inner-container {
    margin-right: 0;
    margin-left: 8px;
  }

  .sidebar {
    left: auto !important;
    right: 0 !important;

    > ul.list-group {
      padding: 0;
    }
  }

  .mat-form-field {
    font-family: 'Tajawal', sans-serif !important;
  }

  .main-container {
    margin-left: 0 !important;
    margin-right: 235px;
  }
  /*rtl dropdown items correction*/
  .dropdown-menu {
    text-align: right;
  }

  * {
    direction: rtl;
    text-align: right;
  }

  .navbar * {
    direction: rtl;

    .btn.btn-fab .fa,
    .btn.btn-fab .material-icons,
    .btn.btn-just-icon .fa,
    .btn.btn-just-icon .material-icons,
    .mat-button.btn.btn-fab .fa,
    .mat-button.btn.btn-fab .material-icons,
    .mat-button.btn.btn-just-icon .fa,
    .mat-button.btn.btn-just-icon .material-icons,
    .mat-raised-button.btn.btn-fab .fa,
    .mat-raised-button.btn.btn-fab .material-icons,
    .mat-raised-button.btn.btn-just-icon .fa,
    .mat-raised-button.btn.btn-just-icon .material-icons,
    .mat-raised-button.btn:not([class*=mat-elevation-z]).btn-fab .fa,
    .mat-raised-button.btn:not([class*=mat-elevation-z]).btn-fab .material-icons,
    .mat-raised-button.btn:not([class*=mat-elevation-z]).btn-just-icon .fa,
    .mat-raised-button.btn:not([class*=mat-elevation-z]).btn-just-icon .material-icons {
      margin-top: 0;
      position: absolute;
      width: 100%;
      -webkit-transform: none;
      transform: none;
      left: 0;
      top: 0;
      height: 100%;
      line-height: 41px;
      font-size: 20px;
    }
  }

  .sidebar * {
    direction: ltr;
  }

  .navbar .dropdown-menu {
    text-align: left;
  }

  .pull-right {
    float: left !important;
    margin-left: 0;
  }

  .mat-button.btn {
    text-align: right;
  }

  .card [class*="card-header-"] .card-icon,
  .card [class*="card-header-"] .card-text {
    float: right !important;
    margin-left: 10px;
  }
.breadcrumb-item + .breadcrumb-item::before {
  padding-left: 0.5rem;
}
  .breadcrumb {
    direction:rtl;
    justify-content: flex-end;
    float: right;
  }

  .datepicker-input {
    direction: ltr;

    .dropdown-menu {
      direction: ltr;

      * {
        direction: ltr;
      }
    }
  }

  .input-group {
    direction: ltr;
  }

  .col-lg-6.col-md-12.ml-auto {
    margin-left: 0 !important;
    margin-right: auto !important;
  }

  .add_btn_top {
    text-align: left !important;
    float: left !important;
  }

  .event_list_w .card-body.text-left h4 {
    text-align: right !important;
  }

  .card_list_w h6.card-category {
    text-align: right !important;
  }

  .event_list_w .card-category .notification {
    margin-right: 8px !important;
  }

  .dropdown-menu {
    left: 0 !important;
    right: auto !important;
  }

  .card.card-product .card-footer {
    direction: ltr;
  }

  .event_list_w .align_w {
    left: 12px !important;
    right: auto !important;
  }

  .l_r_wrap {
    float: left !important;
    margin-left: 12px;
  }

  .search_bar_w .right {
    text-align: left;
  }

  .add_btn_top .btn {
    margin-top: 9px;
  }

  .btn:not(.btn-just-icon):not(.btn-fab) .fa,
  .navbar .navbar-nav > li > a.btn:not(.btn-just-icon):not(.btn-fab) .fa {
    left: 0 !important;
  }

  .card .card-title {
    text-align: right !important;
    float: right;
  }

  .eve_location h3 {
    text-align: center !important;
  }

  .date_style {
    float: right !important;
  }

  .text_left h4 {
    float: right;
    color: #B78637;
  }

  .add_btn.pull-right {
    float: left !important;
    margin-left: 0 !important;
  }

  .speaker_list_w .icons_align {
    left: 0 !important;
    right: auto !important;
  }

  .speaker_list_w .card-body p {
    clear: right;
    text-align: center !important;
  }

  .speaker_list_w .card .card-title {
    text-align: center !important;
    width: 100%;
  }

  .delig_w .stats .td-actions {
    margin-right: auto !important;
    margin-left: 0 !important;
  }

  .mat-form-field-label {
    max-width: 100% !important;
  }

  .card-header .btn {
    background: #B78637 !important;
  }

  .mat-form-field-appearance-legacy .mat-form-field-label {
    top: -2px !important;
    font-size: 15px !important;
    font-weight: normal !important;
    color: #b78637 !important;
  }

  .mat-button,
  .mat-fab,
  .mat-flat-button,
  .mat-icon-button,
  .mat-mini-fab,
  .mat-raised-button,
  .mat-stroked-button {
    font-family: 'Tajawal', sans-serif !important;
  }

  .btn {
    text-align: center !important;
  }

  .btn .material-icons {
    text-align: center !important;
  }

  input.mat-input-element {
    font-size: 15px !important;
  }

  .textarea.mat-input-element {
    font-size: 15px !important;
  }

  .card-product .card-category,
  .card-product .card-description,
  .card-product .card-title {
    clear: right;
  }

  .modal-header .close {
    padding: 1rem;
    margin: -1rem auto -1rem -.5em;
  }

  .modal_n .modal-dialog .modal-header h5 {
    font-size: 19px !important;
  }
  .sponsor_list .card-body p {
    clear: right;
  }
  .delg_w_l .card-body p {
    clear: right;
  }
  .ar_mr_r {
    margin-left: auto !important;
    margin-right: 0 !important;
  }
  .title_nw .card-title{
    text-align: center !important;
    float:none !important;
  }
  .card{
      font-size: 15px !important;
  }
  .event_list_w .card-body.text-left h4{
    width: 100%;
  }
  .sidebar .nav p{
    font-size: 15px;
  }
  .card_list_w h6.card-category{
    font-size: 13px;
  }
  .card_list_w h6.card-category small{
    font-size: 15px;
  }
  .deligate_d_w .card-profile h3{
    text-align: center;
  }
  .deligate_d_w .card-profile h4{
    float: none !important;
    text-align: center !important;
  }
  .deligate_d_w .card-profile p{
    text-align: center !important;
  }
  .tab_wrap .upload_doc_btns .btn i{
    margin-right: 0px !important;
    margin-left: 8px !important;
  }
  .seat_btn{
    text-align: center;
  }
  .table-responsive td.view_btn_1 a{
    text-align: center;
  }
  .comp_list_w .card-profile .card-avatar{
    float: right;
  }
  .comp_list_w .pro_det_w .card-body h6,
  .comp_list_w .pro_det_w .card-body p,
  .comp_list_w .pro_det_w .card-body h4{
    text-align: right !important;
  }
  .breadcrumb{
    display: block;
  }
  .breadcrumb li{
    float: right;
  }
  .modal_n .mat-form-field-subscript-wrapper .mat-error {
    font-size: 13px !important;
  }
  .mat-form-field.mat-form-field-invalid .mat-form-field-label {

     color: #f44336 !important;

 }
 .mat-calendar-table{
   direction: ltr !important;
 }
 .mat-calendar-controls{
    direction: ltr !important;
 }
}


@media screen and (max-width: 992px) {
  .rtl {
    .navbar-brand {
      direction: ltr;
    }

    .main-container {
      margin-right: 0;
    }

    &.push-right {
      .sidebar {
        left: auto !important;
        right: 0 !important;
      }
    }
  }
}
